<template>
  <div class="d-flex flex-column gap-3">
    <div class="d-flex flex-row align-items-center">
      <template v-if="loading">
        <spinner-view small class="ms-3" />
        <div class="text-muted small ms-1">
          Syncing...
        </div>
      </template>
      <template v-else-if="listing.dateDetailsImported">
        Last synced {{ formatDate(listing.dateDetailsImported) }}
      </template>
      <template v-else>
        Never synced
      </template>
    </div>
    <div class="d-flex flex-row align-items-center gap-1">
      <button type="button" class="btn btn-primary btn-sm" :disabled="loading" @click="sync">
        Sync Now
      </button>
      <button v-if="listing.isInactive" type="button" class="btn btn-primary btn-sm" :disabled="loading" @click="listing.updateActiveStatus(true)">
        Mark as Active
      </button>
      <button v-else type="button" class="btn btn-danger btn-sm" :disabled="loading" @click="listing.updateActiveStatus(false)">
        Mark as Inactive
      </button>
    </div>
  </div>
</template>

<script>
import {Listing} from "@/models/Listing";
import SpinnerView from "@/components/common/SpinnerView.vue";

export default {
  components: {SpinnerView},
  props: {
    listing: Listing
  },
  computed: {
    loading() {
      return this.listing.isImportingDetails;
    }
  },
  methods: {
    sync() {
      this.listing.importDetails();
    },
    toggleActiveStatus() {
      this.listing.updateActiveStatus(!this.listing.isActive);
    }
  }
}
</script>
